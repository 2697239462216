/*
 * @Author: lazyzzd
 * @Date: 2021-12-26 22:11:08
 * @LastEditors: lazyzzd
 * @LastEditTime: 2022-07-06 12:37:18
 * @Description: axios封装
 */

import axios from "axios";
import { ElMessage } from "element-plus";
import { tokenHandler } from "@/utils/token";

const service = axios.create({ timeout: 30000 });

//配置发送请求前的拦截器 可以设置token信息
service.interceptors.request.use(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (config: any) => {
    const token = tokenHandler.get();
    if (token) {
      // 判断是否存在token，如果存在的话，则每个http header都加上token
      // Bearer是JWT的认证头部信息
      config.headers.common["Authorization"] = "Bearer " + token;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    const { status, data } = response;
    console.log("🚀 ~ file: request.ts ~ line 36 ~ status", status);

    if (status !== 200) {
      ElMessage({
        type: "error",
        message: "请求失败",
      });
      return Promise.reject(response);
    }

    if (
      response.config.method?.toLocaleLowerCase() === "post" &&
      data.success === false
    ) {
      ElMessage({
        type: "error",
        message: data.message || "下发请求失败",
      });
    }

    return data;
  },
  (error) => {
    if (error.response.status === 401) {
      ElMessage({
        type: "error",
        message: "请先登录",
      });
      window.location.href = `${window.location.origin}/#/login`;
      return Promise.reject(error.response);
    }

    ElMessage({
      type: "error",
      message: "请求失败",
    });
    return Promise.reject(error);
  }
);

export default service;
