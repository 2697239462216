export const tokenHandler = (() => {
  const key = "store_t";
  let token: string | null = localStorage.getItem(key);

  function setToken(t: string): void {
    token = t;
    localStorage.setItem(key, token);
  }

  function getToken(): string | null {
    return token;
  }

  return {
    get: getToken,
    set: setToken,
  };
})();
